import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css']
})
export class NotifyComponent implements OnInit {

  @Input()
  message;

  @Input()
  type;

  constructor() { 
  }

  ngOnInit() {
  }

}
