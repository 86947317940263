import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { PostmanService } from "./services/postman.service";
import { SearchService } from "./services/search.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnDestroy {
  legalEntity = "";
  subscription: Subscription;
  message;
  
  constructor(private ps:PostmanService, private ss: SearchService){
    this.subscription = this.ps.getMessage().subscribe(legalEntity => {
      if (legalEntity) {
      this.legalEntity = legalEntity;
      } else {
        this.legalEntity = "";
      }
    });
  }

  testAPI() {
    this.ss.testAPI()
    .subscribe(data => {
      this.message = data;
  })        

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
}
}
