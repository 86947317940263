import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scip-faq',
  templateUrl: './scip-faq.component.html',
  styleUrls: ['./scip-faq.component.css']
})
export class ScipFaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  download(){
    let link = document.createElement("a");
    link.download = "filename";
    link.href = "assets/blank.xlsx";
    link.click();
  }

}
