import { Component, OnInit } from "@angular/core";
import { PostmanService } from "src/app/services/postman.service";

@Component({
  selector: "app-scip-home",
  templateUrl: "./scip-home.component.html",
  styleUrls: ["./scip-home.component.css"],
})
export class ScipHomeComponent implements OnInit {
  constructor(private ps: PostmanService) {}
  ngOnInit() {}
}
