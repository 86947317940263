import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'scip-search',
  templateUrl: './scip-search.component.html',
  styleUrls: ['./scip-search.component.css']
})
export class ScipSearchComponent implements OnInit {

  searchForm: FormGroup;

  brandNames$;
  scipNumber$;
  isLoading = false;
  isServerError = false;

  @Input()
  module;
  hasNoData = false;
  hasData = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private ss: SearchService) {}

  ngOnInit() {
    this.brandNames$ = this.ss.getBrandNames();
    this.searchForm = this.formBuilder.group({
      partNumber: ['', Validators.required],
      brand: ['', Validators.required]
    });
  }

  onBrandChange() {
    this.isServerError = false;
    this.hasNoData = false;
    this.hasData = false;
  }

  onSearchChange() {
    this.isServerError = false;
    this.hasNoData = false;
    this.hasData = false;
  }

  handleSearch() {
    this.isLoading = true;
    this.isServerError = false;

    this.scipNumber$ = this.ss.getSingleSCIPDetails(this.searchForm.value)
      .pipe(
        tap( (response) => {
          if (response['searchCount'] === 0) {
            this.hasNoData = true;
          } else {
            this.hasData = true;
          }
          this.isLoading = false;
        }),
        catchError(err => {
          this.isServerError = true;
          this.isLoading = false;
          throw new Error('error in source. Details: ' + err);
        }),
      );
  }

}
